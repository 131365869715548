import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useAppState } from '../../state';
import IntroContainer from '../IntroContainer/IntroContainer';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme, Link } from '@material-ui/core';

// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { allowedNodeEnvironmentFlags } from 'process';

var otherUserName = 'Test';
var userLookupAlias = '';

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        textAlign: 'center',
    },
    textFieldContainer: {
        width: '100%',
    },
    continueButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '.5em',
        },
    },
    anchor: {
        textDecoration: 'none',
    },
    content: {
        textAlign: 'center',
    },
    imgDiv: {
        textAlign: 'center',
    },
    submitButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

export default function UserPostMeetingPage() {
    const [userNameAvaialbe, setUserNameAvaialbe] = useState(true);
    const [userHandle, setUserHandle] = useState('');
    const [helperText, sethelperText] = useState('');
    const [showButton, setShowButton] = useState(false);
    const { firebaseUser } = useAppState();
    var [chatTextCopied, setChatTextCopied] = useState(false);
    var [chatTextLabel, setChatTextLabel] = useState('');
    const classes = useStyles();

    const imageClick = () => {
        alert('Email invite@joincoffeebreak.com to get early access to the app!');
    };

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        var patt = /^[0-9a-zA-Z][\b]+$/;
        var temp = event.target.value;
        const isValid = /^[0-9a-zA-Z]+$/.test(temp);

        if (isValid || temp === '') {
            setUserHandle(temp);
            handleUserNameSearch(temp);
        }
    };

    const handleUserNameSearch = (val: String) => {
        if (val.length == 0) {
            setUserNameAvaialbe(true);
            sethelperText('');
            setShowButton(false);

            return;
        }

        checkIfUsernameAvailable(val);
    };

    window.onbeforeunload = event => {
        window.sessionStorage.setItem('meetingStatus', '');
        window.sessionStorage.setItem('URLIdentity', '');
        window.sessionStorage.setItem('URLMeetingId', '');
        // Cancel the event
        e.preventDefault();
        if (e) {
            e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
    };

    function getMeetingInfo() {
        const db = firebase.firestore();
        const docRef = db.collection('meetings').doc(firebase.auth().currentUser?.uid);

        console.log('About to call DB');
        docRef
            .collection('meetings')
            .get()
            .then(querySnapshot => {
                setReadyToShow(true);
                querySnapshot.forEach(doc => {
                    const d = doc.data()['startDateTimeAsDate'];
                    const min = d.toMillis() - 5 * 60 * 1000;
                    const max = d.toMillis() + 15 * 60 * 1000;
                    const nowInMil = Date.now();

                    if (nowInMil >= min && nowInMil <= max) {
                        const temp2 = doc.data();
                        console.log(temp2);

                        if (!temp2['guestPhoto']) {
                            temp2.guestPhoto = '';
                        } else {
                            console.log(temp2.guestPhoto);
                        }

                        //if (temp2.status === 'Accepted') {
                        temp.push(temp2);
                    }
                    //}
                });
                setUserMeetings(temp);
            });
    }

    function checkIfUsernameAvailable(val: String) {
        try {
            console.log('checking: ', '@' + val.toLocaleLowerCase());
            const docRef = firebase
                .firestore()
                .collection('userNames')
                .doc('@' + val.toLocaleLowerCase());

            docRef.get().then(doc => {
                if (doc.exists) {
                    setUserNameAvaialbe(false);
                    sethelperText('Username is not available!');
                    setShowButton(false);
                } else {
                    setUserHandle(val);
                    setUserNameAvaialbe(true);
                    sethelperText('Username is available!');
                    setShowButton(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    if (firebaseUser) {
        return renderUserForm();
    } else {
        return renderAnonForm();
    }

    function copyChatText() {
        var temp = window.sessionStorage.getItem('latest-coffeeBreakConverstion');
        setChatTextCopied(true);

        if (temp === null || temp === 'null' || temp === 'undefined') {
            setChatTextLabel('No chat text');
            return;
        } else {
            setChatTextLabel('Chat text copied');
            navigator.clipboard.writeText(temp);
        }
    }

    function renderAnonForm() {
        console.log('userNameAvaialbe: ', userNameAvaialbe);

        return (
            <IntroContainer>
                <Typography variant="h6" className={classes.content}>
                    Congratulations on your coffeeBreak!
                </Typography>
                <>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            color="inherit"
                            variant="contained"
                            onClick={() => {
                                copyChatText();
                                //setStartTime(meetingDuration + 120000);
                                //setHidden2(true);
                                //add2MinCount = 1;
                            }}
                        >
                            Get chat text
                        </Button>
                        <br />
                        {chatTextCopied && (
                            <Typography
                                style={{ paddingLeft: '10px' }}
                                color="primary"
                                variant="caption"
                                className={classes.content}
                            >
                                {chatTextLabel}
                            </Typography>
                        )}
                    </div>
                </>
                <Typography variant="h6" className={classes.content}>
                    <br></br>
                    <div className={classes.content}>
                        Download the app to join
                        <div className={classes.imgDiv}>
                            <a href="https://testflight.apple.com/join/C24aHisp" taget="_blank">
                                <img width="125px" src="https://joincoffeebreak.com/appStore.png"></img>
                            </a>
                        </div>
                    </div>
                    <br></br>
                    <div className={classes.content}>
                        <div className={classes.textFieldContainer}>
                            <div className={classes.content}>Check if username is available</div>
                            <TextField
                                size="small"
                                error={!userNameAvaialbe}
                                id="outlined-error-helper-text"
                                placeholder="You preferred username"
                                helperText={helperText}
                                onChange={handleInput}
                                variant="outlined"
                                value={userHandle}
                            />
                        </div>

                        {showButton && (
                            <Button
                                variant="contained"
                                type="submit"
                                onClick={() => {
                                    window.open('/login', '_self');
                                }}
                                color="primary"
                                className={classes.continueButton}
                            >
                                Sign in / Create account
                            </Button>
                        )}
                    </div>
                </Typography>
            </IntroContainer>
        );
    }

    function renderUserForm() {
        return (
            <IntroContainer>
                <Typography variant="h6" className={classes.content}>
                    Congratulations on your coffeeBreak!
                </Typography>
                <>
                    <div style={{ textAlign: 'center' }}>
                        <p />
                        <Button
                            color="inherit"
                            variant="contained"
                            onClick={() => {
                                copyChatText();
                                //setStartTime(meetingDuration + 120000);
                                //setHidden2(true);
                                //add2MinCount = 1;
                            }}
                        >
                            Get chat text
                        </Button>
                        <br />
                        {chatTextCopied && (
                            <Typography
                                style={{ paddingLeft: '10px' }}
                                color="primary"
                                variant="caption"
                                className={classes.content}
                            >
                                {chatTextLabel}
                            </Typography>
                        )}
                    </div>
                </>
            </IntroContainer>
        );
    }
}
