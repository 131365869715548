import { useCallback, useEffect, useState } from 'react';
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import { Console } from 'node:console';
import { StringLiteralType } from 'typescript';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export default function useFirebaseAuth() {
    const [user, setUser] = useState<firebase.User | null>(null);
    const [isAuthReady, setIsAuthReady] = useState(false);

    //Get Datetime Now in UTC
    const date = new Date();
    const date2 = new Date(date.toUTCString());
    const clientDateTimeNow = date2.getTime();

    const getToken = useCallback(
        async (identity: string, meetingId: string, userEmail: StringLiteralType) => {
            //here we need to check if this is a permalink meeting by seeing if the meeting id is an actual user id
            //if not continute normally
            //if so blank it out and token server does the rest

            const docRef = firebase
                .firestore()
                .collection('meetings')
                .doc(meetingId);
            var hostUid = '';
            const headers = new window.Headers();
            var endpoint = process.env.REACT_APP_TOKEN_ENDPOINT; //|| '/token';
            var identityParam = '';

            await docRef.get().then(async doc => {
                if (doc.exists) {
                    console.log('This is an app based meeting');
                    hostUid = '';
                } else {
                    console.log('This is a permalink meeting');
                    hostUid = meetingId;
                    meetingId = '';
                }
            });

            if (user == null) {
                identityParam = encodeURIComponent(identity);
            } else {
                const idToken = await user!.getIdToken();
                headers.set('Authorization', idToken);

                var meetingDisplayName = '';
                var names = user.displayName?.split(' ');

                if (names != null && names?.length > 1) {
                    meetingDisplayName = names[0] + ' ' + names[1].substring(0, 1) + '.';
                } else {
                    meetingDisplayName = identity;
                }

                identityParam = encodeURIComponent(meetingDisplayName);
            }

            if (hostUid === '' && meetingId != '') {
                endpoint +=
                    '?meetingId=' +
                    meetingId +
                    '&identity=' +
                    identityParam +
                    '&userEmail=' +
                    userEmail +
                    '&clientDateTimeNow=' +
                    clientDateTimeNow;
            } else if (meetingId == '' && hostUid != '') {
                endpoint +=
                    '?identity=' +
                    identityParam +
                    '&userEmail=' +
                    userEmail +
                    '&hostUid=' +
                    hostUid +
                    '&clientDateTimeNow=' +
                    clientDateTimeNow;
            }

            headers.set('accept', 'application/json');
            headers.set('Access-Control-Allow-Origin', '*');

            console.log('\n\n\n\nendpoint: ', endpoint);
            console.log('\n\n\n\n*************** ++ ABOUT TO CALL GET TOKEN ***************');

            return fetch(endpoint, {
                //mode: 'no-cors',
                method: 'GET',
                headers,
            }).then(res => res.text());
        },
        [user]
    );

    useEffect(() => {
        firebase.auth().onAuthStateChanged(newUser => {
            setUser(newUser);
            setIsAuthReady(true);
        });
    }, []);

    const googleSignIn = useCallback(() => {
        const provider = new firebase.auth.GoogleAuthProvider();

        provider.addScope('https://www.googleapis.com/auth/plus.login');

        return firebase
            .auth()
            .signInWithPopup(provider)
            .then(newUser => {
                setUser(newUser.user);
            });
    }, []);

    const emailSignIn = useCallback(() => {
        const provider = new firebase.auth.EmailAuthProvider();

        return firebase
            .auth()
            .signInWithPopup(provider)
            .then(newUser => {
                setUser(newUser.user);
            });
    }, []);
    const signOut = useCallback(() => {
        return firebase
            .auth()
            .signOut()
            .then(() => {
                setUser(null);
            });
    }, []);

    return { user, signIn: emailSignIn, googleSignIn, signOut, isAuthReady, getToken };
}
