import React, { useCallback, useRef } from 'react';
import Button from '@material-ui/core/Button';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';
import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const lastClickTimeRef = useRef(0);
    const { hasVideoInputDevices } = useDevices();

    const toggleVideo = useCallback(() => {
        const analytics = firebase.analytics();

        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            toggleVideoEnabled();
        }

        if (isVideoEnabled) {
            analytics.logEvent('video_enabled');
        } else {
            analytics.logEvent('video_disabled');
        }
    }, [toggleVideoEnabled]);

    return (
        <Button
            className={props.className}
            onClick={toggleVideo}
            disabled={!hasVideoInputDevices || props.disabled}
            startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
        >
            {!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Camera' : 'Camera'}
        </Button>
    );
}
