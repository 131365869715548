import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAppState } from '../../state';
import { Typography, TextField, Grid, Button, InputLabel } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import IntroContainer from '../IntroContainer/IntroContainer';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardContainer from '../IntroContainer/CardContainer';

// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const temp = window.location.href.split('/');

const userName = temp[temp.length - 1];
console.log(userName);

declare const seafoamGreen: {
    50: '#37d2d2';
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divPadding: {
            paddingLeft: '10%',
            paddingRight: '10%',
            paddingTop: 10,
        },

        root: {
            overflowY: 'scroll',
            maxHeight: 600,
            maxWidth: 500,
            [theme.breakpoints.down('md')]: {
                maxWidth: 400,
                maxHeight: 500,
            },
        },
        media: {
            marginBottom: '-40px',
            maxHeight: 350,
            clipPath:
                'polygon(100% 0%, 0% 0% , 0% 92.5%, 1% 92.475%, 2% 92.45%, 3% 92.375%, 4% 92.275%, 5% 92.125%, 6% 91.975%, 7% 91.8%, 8% 91.575%, 9% 91.35%, 10% 91.1%, 11% 90.825%, 12% 90.525%, 13% 90.2%, 14% 89.875%, 15% 89.525%, 16% 89.175%, 17% 88.8%, 18% 88.425%, 19% 88.05%, 20% 87.65%, 21% 87.275%, 22% 86.9%, 23% 86.525%, 24% 86.15%, 25% 85.775%, 26% 85.425%, 27% 85.075%, 28% 84.75%, 29% 84.425%, 30% 84.15%, 31% 83.875%, 32% 83.6%, 33% 83.375%, 34% 83.175%, 35% 83%, 36% 82.85%, 37% 82.725%, 38% 82.625%, 39% 82.55%, 40% 82.5%, 41% 82.5%, 42% 82.525%, 43% 82.575%, 44% 82.65%, 45% 82.75%, 46% 82.9%, 47% 83.05%, 48% 83.25%, 49% 83.45%, 50% 83.7%, 51% 83.95%, 52% 84.225%, 53% 84.525%, 54% 84.85%, 55% 85.175%, 56% 85.525%, 57% 85.9%, 58% 86.25%, 59% 86.625%, 60% 87.025%, 61% 87.4%, 62% 87.775%, 63% 88.175%, 64% 88.55%, 65% 88.925%, 66% 89.275%, 67% 89.625%, 68% 89.975%, 69% 90.3%, 70% 90.625%, 71% 90.9%, 72% 91.175%, 73% 91.425%, 74% 91.65%, 75% 91.85%, 76% 92.025%, 77% 92.175%, 78% 92.3%, 79% 92.4%, 80% 92.45%, 81% 92.5%, 82% 92.5%, 83% 92.475%, 84% 92.425%, 85% 92.35%, 86% 92.225%, 87% 92.075%, 88% 91.925%, 89% 91.725%, 90% 91.5%, 91% 91.275%, 92% 91%, 93% 90.725%, 94% 90.425%, 95% 90.1%, 96% 89.75%, 97% 89.4%, 98% 89.05%, 99% 88.675%, 100% 88.3%)',
        },
        avatar: {
            backgroundColor: '#37d2d2',
        },
    })
);

export default function UserPage({}) {
    const { user } = useAppState();

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const db = firebase.firestore();

    var [firstName, setFirstName] = useState('');
    var [lastName, setLastName] = useState('');
    var [jobTitle, setJobTitle] = useState('');
    var [city, setCity] = useState('');
    var [company, setCompany] = useState('');
    var [profileImage, setProfileImage] = useState('');
    var [intro, setIntro] = useState('');
    var [goals, setGoals] = useState(Array<String>());
    var [collegeAttended, setCollegeAttended] = useState('');
    var [spokenLanguages, setSpokenLanguages] = useState('');

    db.collection('users')
        .where('userHandle', '==', '@' + userName)
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                // doc.data() is never undefined for query doc snapshots
                setFirstName(doc.data()['first_name']);
                setLastName(doc.data()['last_name']);
                setJobTitle(doc.data()['jobTitle']);
                setCity(doc.data()['location']);
                setCompany(doc.data()['company']);
                setProfileImage(doc.data()['photo']);
                setIntro(doc.data()['introduction']);
                setGoals(doc.data()['goals']);
                setCollegeAttended(doc.data()['collegeAttended']);
                setSpokenLanguages(doc.data()['spokenLanguages']);

                console.log(profileImage);
            });
        })
        .catch(error => {
            console.log('**********************');
            console.log('Error getting documents: ', error);
        });

    return (
        <>
            {firstName.length > 2 && (
                <body>
                    <link
                        rel="stylesheet"
                        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
                    />
                    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                    <div id="root">
                        <CardContainer>
                            <div className={classes.divPadding}>
                                <Card className={classes.root}>
                                    <CardHeader
                                        avatar={
                                            <Avatar aria-label="recipe" className={classes.avatar}>
                                                {firstName.substring(0, 1)}
                                            </Avatar>
                                        }
                                        title={firstName + ' ' + lastName.substring(0, 1) + '.'}
                                        subheader={jobTitle + ' @ ' + company}
                                    />
                                    <CardMedia
                                        component="img"
                                        className={classes.media}
                                        image={profileImage}
                                        title={userName}
                                    />

                                    <CardContent>
                                        <Typography variant="body" color="textPrimary" component="div">
                                            {intro}
                                        </Typography>
                                        <Typography variant="body2" color="textPrimary" component="br"></Typography>
                                        <Typography variant="body" color="textPrimary" component="p">
                                            <b>City:</b> {city}
                                        </Typography>
                                        <Typography variant="body" color="textPrimary" component="p">
                                            <b>Education:</b> {collegeAttended}
                                        </Typography>
                                        <Typography variant="body" color="textPrimary" component="p">
                                            <b>Languages:</b> {spokenLanguages}
                                        </Typography>

                                        <Typography variant="body" color="textPrimary" component="p">
                                            <p></p>
                                            <b>Goals</b>{' '}
                                            <ul>
                                                {goals.map(function(name, index) {
                                                    return <li key={index}>{name}</li>;
                                                })}
                                            </ul>
                                        </Typography>
                                    </CardContent>
                                    <CardActions disableSpacing>
                                        <IconButton aria-label="share">
                                            <ShareIcon />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </div>
                        </CardContainer>
                    </div>
                </body>
            )}
        </>
    );
}
