import { Redirect } from 'react-router-dom';
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import React, { useImperativeHandle } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useAppState } from '../../state';
import './LoginPage.scss';
import { ThreeDots } from 'react-loader-spinner';

export default function LoginPage() {
    const { user, isAuthReady } = useAppState();
    const [showLoginPage, setShowPageLogin] = React.useState(true);
    const [showSpinner, setShowSpinner] = React.useState(false);

    if (!isAuthReady) {
        return null;
    }

    const uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: function() {
                setShowPageLogin(false);
                setShowSpinner(true);

                // User successfully signed in.
                // Return type determines whether we continue the redirect automatically
                // or whether we leave that to developer to handle.
                var user = firebase.auth().currentUser;
                var fname, lname, email, photoUrl, uid, emailVerified;

                if (user != null) {
                    const userNameArray = user.displayName?.split(' ');

                    if (userNameArray?.length == 2) {
                        fname = userNameArray[0];
                        lname = userNameArray[1];
                    } else {
                        fname = 'Guest';
                        lname = 'User';
                    }

                    email = user.email;
                    photoUrl =
                        'https://firebasestorage.googleapis.com/v0/b/coffebreak-a15f1.appspot.com/o/profileImages%2FDefaultUserProfile.jpeg?alt=media';

                    uid = user.uid;

                    // The user's ID, unique to the Firebase project. Do NOT use
                    // this value to authenticate with your backend server, if
                    // you have one. Use User.getToken() instead.

                    //get room name and display name from db

                    const userHandle = '@' + email?.split('@')[0];

                    const encoded = encodeURI(photoUrl);
                    var url =
                        'https://us-central1-coffebreak-a15f1.cloudfunctions.net/functionCreateUserDoc' +
                        '?uid=' +
                        uid +
                        '&userHandle=' +
                        userHandle +
                        '&email=' +
                        email +
                        '&photoUrl=' +
                        encoded +
                        '&first_name=' +
                        fname +
                        '&last_name=' +
                        lname;

                    console.log('url: ', url);

                    try {
                        fetch(url, {
                            method: 'GET',
                            //mode: 'no-cors',
                        }).then(res => {
                            if (!res.ok) {
                                console.log('error: ', res);
                                return false;
                            }
                            window.location = '/mylinks';
                            return true;
                        });
                    } catch (error) {
                        console.log(error);
                        return;
                    }
                } else {
                    console.log('user object was null');
                }
            },
        },
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        // We will display Google and email as auth providers.
        signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.EmailAuthProvider.PROVIDER_ID],
    };

    if (showLoginPage) {
        return (
            <div className="login-page">
                <div className="modal">
                    <div className="modal-brand">
                        <img src="/coffeeBreak.png" alt="" />
                    </div>
                    <div className="modal-content">
                        <h5>Sign in / Create account</h5>
                        <div>
                            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="login-page">
                <div className="modal">
                    <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#ffffff"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={showSpinner}
                    />
                </div>
            </div>
        );
    }
}
