import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ScreenShareIcon from '../../../icons/ScreenShareIcon';
import Tooltip from '@material-ui/core/Tooltip';
import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            '&[disabled]': {
                color: '#bbb',
                '& svg *': {
                    fill: '#bbb',
                },
            },
        },
    })
);

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
    const classes = useStyles();
    const screenShareParticipant = useScreenShareParticipant();
    const { toggleScreenShare } = useVideoContext();
    const disableScreenShareButton = Boolean(screenShareParticipant);
    const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
    const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;

    let tooltipMessage = '';
    const analytics = firebase.analytics();

    if (disableScreenShareButton) {
        tooltipMessage = SHARE_IN_PROGRESS_TEXT;
        analytics.logEvent('screenshare_active');
    }

    if (!isScreenShareSupported) {
        tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
        analytics.logEvent('screenshare_inactive');
    }

    return (
        <Tooltip
            title={tooltipMessage}
            placement="top"
            PopperProps={{ disablePortal: true }}
            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
        >
            <span>
                {/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
                <Button
                    className={classes.button}
                    onClick={toggleScreenShare}
                    disabled={isDisabled}
                    startIcon={<ScreenShareIcon />}
                    data-cy-share-screen
                >
                    {SCREEN_SHARE_TEXT}
                </Button>
            </span>
        </Tooltip>
    );
}
