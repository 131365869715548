import React, { useState, ChangeEvent, FormEvent, useRef } from 'react';
import { useAppState } from '../../state';
import IntroContainer from '../IntroContainer/IntroContainer';
import { Typography, makeStyles, Button, Theme } from '@material-ui/core';
import 'firebase/firestore';
import { Redirect } from 'react-router-dom';

var otherUserName = 'Test';
var userLookupAlias = '';

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        textAlign: 'center',
    },
    textFieldContainer: {
        width: '100%',
    },
    continueButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '.5em',
        },
    },
    anchor: {
        textDecoration: 'none',
    },
    content: {
        textAlign: 'center',
    },
    imgDiv: {
        textAlign: 'center',
    },
    submitButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

export default function UserMyLinksPage() {
    const [userNameAvaialbe, setUserNameAvaialbe] = useState(true);
    const [userHandle, setUserHandle] = useState('');
    const [helperText, sethelperText] = useState('');
    const [showButton, setShowButton] = useState(false);
    const { firebaseUser } = useAppState();
    var [link1Copied, setlink1Copied] = useState(false);
    var [link2Copied, setlink2Copied] = useState(false);
    const classes = useStyles();

    function copyText(s) {
        navigator.clipboard.writeText(document.getElementById(s).innerHTML);
    }

    function getGuestLink() {
        if (firebaseUser != null) {
            return 'https://joincoffeebreak.com/join/invite/meetingId/' + firebaseUser.uid + '/identity';
        }
    }

    function getHostLink() {
        if (firebaseUser != null) {
            const names = firebaseUser.displayName?.split(' ');

            if (names != null) {
                return (
                    'https://joincoffeebreak.com/meetingId/' +
                    firebaseUser.uid +
                    '/identity/' +
                    names[0] +
                    '%20' +
                    names[1][0] +
                    './?h=1'
                );
            }
        }
    }

    return renderUserForm();

    function renderUserForm() {
        console.log('userNameAvaialbe: ', userNameAvaialbe);

        return (
            <IntroContainer>
                <div style={{ marginTop: '-40px', paddingTop: '0' }}>
                    <Typography variant="body1">
                        <p />
                        Share the <b>Guest Link</b> and have a coffeeBreak with anyone you want.
                        <p />
                        For <b>Calendly</b> integration: Create a custom <b>Event Type</b>, then set <b>Location</b> to{' '}
                        <b>Guest Link</b>. Join the meeting with <b>Host Link</b>.
                    </Typography>
                </div>

                <br />

                <b>Guest Link</b>

                <pre
                    style={{ wordBreak: 'break-all', whiteSpace: 'pre-line', marginTop: '0px', marginBottom: '5px' }}
                    id="link2"
                >
                    {getGuestLink()}
                </pre>

                <Button
                    variant="outlined"
                    type="submit"
                    color="primary"
                    onClick={() => {
                        copyText('link2');
                        setlink2Copied(true);
                    }}
                    className={classes.continueButton}
                >
                    Copy Link
                </Button>
                {link2Copied && (
                    <Typography
                        style={{ paddingLeft: '10px' }}
                        color="primary"
                        variant="caption"
                        className={classes.content}
                    >
                        Link copied!
                    </Typography>
                )}
                <br />
                <br />
                <b>Host Link (you)</b>
                <pre
                    style={{ wordBreak: 'break-all', whiteSpace: 'pre-line', margin: '0px', marginBottom: '-10px' }}
                    id="link1"
                >
                    {getHostLink()}
                </pre>
                <p></p>
                <Button
                    variant="outlined"
                    type="submit"
                    onClick={() => {
                        copyText('link1');
                        setlink1Copied(true);
                    }}
                    color="primary"
                    className={classes.continueButton}
                >
                    Copy Link
                </Button>

                {link1Copied && (
                    <Typography
                        style={{ paddingLeft: '10px' }}
                        color="primary"
                        variant="caption"
                        className={classes.content}
                    >
                        Link copied!
                    </Typography>
                )}
            </IntroContainer>
        );
    }
}
