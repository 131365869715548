import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, ButtonGroup } from '@material-ui/core';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import AvatarIcon from '../../icons/AvatarIcon';
import Typography from '@material-ui/core/Typography';
import { useTimeout, useWindowSize } from 'react-use';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import usePublications from '../../hooks/usePublications/usePublications';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useTrack from '../../hooks/useTrack/useTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import Confetti from 'react-confetti';
import firebase from 'firebase/compat/app';
import 'firebase/analytics';
import 'firebase/firestore';

//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

function setDisableCutoffInDb(val: Boolean, room: string) {
    try {
        console.log('val= ', val);
        firebase
            .firestore()
            .collection('meetingStartInfo')
            .doc(room)
            .update({
                disableCutoff: val,
            })
            .catch(error => {
                console.error('Error writing document: ', error);
            });
    } catch (error) {
        console.log(error);
    }
}

var meetingDuration = 900000; //15 min
const Default_Meeting_Duration = 900000;

var add2MinCount = 0;
var add5MinCount = 0;
const MeetingNotStarted = () => <div>Waiting for other person to join</div>;
const img2MinWarnUrl = process.env.PUBLIC_URL + '/2minwarningv4.png';
const TwoMinWarn = () => (
    <div className={clsx(useStyles().foo)}>
        <img src={img2MinWarnUrl} className={clsx(useStyles().twoMinWarning)}></img>
    </div>
);

var showRemainingTime: Boolean = false;
var showTwoMinWarning: Boolean = false;
var showWaiting: Boolean = true;
var isHostAnAdmin: Boolean = false;
var durationUpdated: Boolean = false;
var isUserHost: Boolean = false;
var startTime = 0;
const queryString = require('query-string');
const parsed = queryString.parse(window.location.search);

const useStyles = makeStyles((theme: Theme) => ({
    '@keyframes blinker': {
        '90%': {
            opacity: 0,
        },
    },
    debug: {
        marginTop: '60px',
        color: 'white',
    },
    twoMinWarning: {
        animation: '$blinker 2s linear infinite',
        opacity: 0.75,
        width: '70%',

        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
    },
    foo: {
        width: 'auto',
        textAlign: 'center',
    },
    img: {
        maxWidth: '100%',
        height: 'auto',
    },
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    identity: {
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '0.1em 1em .1em 0',
        fontSize: '1.2em',
        display: 'flex',
        width: '19em',
        height: '5em',
        '& svg': {
            marginLeft: '0.3em',
        },
    },
    infoContainer: {
        position: 'absolute',
        zIndex: 2,
        height: '100%',
        width: '100%',
    },
    reconnectingContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(40, 42, 43, 0.75)',
        zIndex: 1,
    },
    fullWidth: {
        gridArea: '1 / 1 / 2 / 3',
        [theme.breakpoints.down('sm')]: {
            gridArea: '1 / 1 / 3 / 3',
        },
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'black',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        '& svg': {
            transform: 'scale(2)',
        },
    },
    circle: {
        height: '12px',
        width: '12px',
        background: 'red',
        borderRadius: '100%',
        margin: '0 0.6em',
        animation: `1.25s $pulsate ease-out infinite`,
    },
    '@keyframes pulsate': {
        '0%': {
            background: `#A90000`,
        },
        '50%': {
            background: '#f00',
        },
        '100%': {
            background: '#A90000',
        },
    },
}));

interface MainParticipantInfoProps {
    participant: Participant;
    children: React.ReactNode;
}

export default function MainParticipantInfo({ participant, children }: MainParticipantInfoProps) {
    const { width, height } = useWindowSize();
    const classes = useStyles();
    const { room } = useVideoContext();
    const localParticipant = room!.localParticipant;
    const isLocal = localParticipant === participant;
    const screenShareParticipant = useScreenShareParticipant();
    const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;
    const publications = usePublications(participant);
    const videoPublication = publications.find(p => p.trackName.includes('camera'));
    const screenSharePublication = publications.find(p => p.trackName.includes('screen'));
    const videoTrack = useTrack(screenSharePublication || videoPublication);
    const isVideoEnabled = Boolean(videoTrack);
    const audioPublication = publications.find(p => p.kind === 'audio');
    const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
    const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
    const isParticipantReconnecting = useParticipantIsReconnecting(participant);
    const [isComplete] = useTimeout(4000);
    const [disableCutoff, setDisableCutoff] = useState(false);
    const [hidden2 = true, setHidden2] = useState(true);
    const [hidden5 = true, setHidden5] = useState(true);

    const forceUpdate = useForceUpdate();

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDisableCutoff(event.target.checked);
        setDisableCutoffInDb(event.target.checked, room?.name);
    };

    async function setDurationFromQueryString() {
        if (room?.name === 'test99' && parsed.duration) {
            clearStartTimeFromDb();
            meetingDuration = Number(parsed.duration);
        }
    }

    function clearActiveMeeting(hostUid: string) {
        var url =
            'https://us-central1-coffebreak-a15f1.cloudfunctions.net/functionClearHostActiveMeeting' +
            '?hostUid=' +
            hostUid;

        const encoded = encodeURI(url);
        console.log('url: ', encoded);

        fetch(encoded, {
            method: 'GET',
            //mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(async res => {
            if (!res.ok) {
                console.log('error: ', res);
            }
        });
    }

    function clearStartTimeFromDb() {
        try {
            const docRef = firebase
                .firestore()
                .collection('meetingStartInfo')
                .doc(room?.name)
                .delete()
                .then(() => {
                    setStartTime();
                    console.log('Document successfully deleted!');
                })
                .catch(error => {
                    console.error('Error removing document: ', error);
                });
        } catch (error) {
            console.log(error);
        }
    }

    async function okToCutoff(roomName: string, room: typeof useVideoContext) {
        try {
            const docRef = firebase
                .firestore()
                .collection('meetingStartInfo')
                .doc(roomName);

            docRef.get().then(async doc => {
                if (doc.exists) {
                    if (doc.data()['disableCutoff']) {
                        const disable = doc.data()['disableCutoff'];
                        if (disable) {
                            return;
                        }
                    }
                }

                endExpiredMeeting();
            });
        } catch (error) {
            console.log(error);
        }
    }

    function shutDown() {
        const handle = setTimeout(() => {
            try {
                window.sessionStorage.setItem('meetingStatus', 'Complete');
                okToCutoff(room?.name, room);
            } catch (e) { }
        }, 4000);
    }

    async function startIfBothConnected() {
        setDurationFromQueryString();

        try {
            if (
                room?.participants.entries().next().value[1].state === 'connected' &&
                localParticipant.state === 'connected'
            ) {
                await setStartTime();
                showWaiting = false;
                showRemainingTime = true;

                var url =
                    'https://us-central1-coffebreak-a15f1.cloudfunctions.net/functionUpdateMeetingStatus' +
                    '?meetingId=' +
                    room?.name +
                    '&status=Joined';
                const encoded = encodeURI(url);
                console.log('url: ', encoded);

                try {
                    fetch(encoded, {
                        method: 'GET',
                        mode: 'no-cors',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (e) {
                    console.log(e);
                }
                //return renderMeetingInfo();
            } else {
                //console.log('remote: ', room?.participants.entries().next().value[1].state);
                //console.log('local: ', localParticipant.state);
                showWaiting = true;
                showRemainingTime = false;
            }
        } catch (e) {
            console.log(e);
        }
    }
    //is user a host?
    if (queryString.parse(window.location.search) && queryString.parse(window.location.search).h == 1) {
        isUserHost = true;
    } else {
        isUserHost = false;
    }

    //is host an admin?
    if (queryString.parse(window.location.search) && queryString.parse(window.location.search).ha == 1) {
        isHostAnAdmin = true;
    } else {
        isHostAnAdmin = false;
    }

    startIfBothConnected();

    firebase
        .firestore()
        .collection('meetingStartInfo')
        .doc(room?.name)
        .onSnapshot(doc => {
            if (doc.data() && doc.data()['meetingDuration']) {
                var tmp = Number(doc.data()['meetingDuration']);
                if (tmp != meetingDuration) {
                    meetingDuration = tmp;
                    forceUpdate();
                }
            }
        });

    function endExpiredMeeting() {
        const analytics = firebase.analytics();
        analytics.logEvent('endcall_button_press');

        var temp = window.location.pathname.substring('meetingId');
        var pathArray = temp.split('/');
        var hostUid = '';

        room!.disconnect();
        if (pathArray.length > 2) {
            hostUid = pathArray[2];
            console.log(hostUid);

            var url =
                'https://us-central1-coffebreak-a15f1.cloudfunctions.net/functionClearHostActiveMeeting' +
                '?hostUid=' +
                hostUid;

            const encoded = encodeURI(url);
            console.log('url: ', encoded);
            try {
                startTime = 0;
                const docRef = firebase
                    .firestore()
                    .collection('meetingStartInfo')
                    .doc(room?.name)
                    .delete()
                    .then(() => {
                        console.log('Document successfully deleted!');
                        try {
                            fetch(encoded, {
                                method: 'GET',
                                //mode: 'no-cors',
                            }).then(async res => {
                                if (!res.ok) {
                                    console.log('error: ', res);
                                }
                            });
                        } catch (error) {
                            console.log(error);
                        }
                    })
                    .catch(error => {
                        console.error('Error removing document: ', error);
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }

    async function setStartTime(newDuration?: number) {
        try {
            const docRef = firebase
                .firestore()
                .collection('meetingStartInfo')
                .doc(room?.name);

            await docRef.get().then(doc => {
                if (doc.exists) {
                    startTime = Number(doc.data()['startTime']);

                    //expired?
                    const currentTime = Date.now();
                    const longestMeeting = 1320000; //15 min + 5 min + 2 min

                    if (currentTime > startTime + longestMeeting) {
                        endExpiredMeeting();
                        return;
                    }

                    if (typeof newDuration !== 'undefined') {
                        console.error('newDuration: ', newDuration);
                        firebase
                            .firestore()
                            .collection('meetingStartInfo')
                            .doc(room?.name)
                            .update({
                                meetingDuration: newDuration,
                            })
                            .then(() => { })
                            .catch(error => {
                                console.error('Error writing document: ', error);
                            });
                    }
                } else {
                    startTime = Date.now();
                    firebase
                        .firestore()
                        .collection('meetingStartInfo')
                        .doc(room?.name)
                        .set(
                            {
                                startTime: startTime,
                            },
                            { merge: true }
                        )
                        .then(() => {
                            startTime = Date.now();
                        })
                        .catch(error => {
                            console.error('Error writing document: ', error);
                        });
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    function formatTime(minutes: number, seconds: number) {
        var strMin = '';
        var strSec = '';

        if (minutes < 10) {
            strMin = '0' + minutes.toString();
        } else {
            strMin = minutes.toString();
        }

        if (seconds < 10) {
            strSec = '0' + seconds.toString();
        } else {
            strSec = seconds.toString();
        }

        return strMin + ':' + strSec;
    }

    const [count, setCount] = useState(0);

    const renderer = ({ api, hours, minutes, seconds, completed }: CountdownRenderProps) => {
        if (minutes <= 1 && minutes > 0 && seconds > 50) {
            showTwoMinWarning = true;

            if (
                room?.participants.entries().next().value[1].state === 'connected' &&
                localParticipant.state === 'connected'
            ) {
                if (isUserHost && add2MinCount == 0) {
                    setHidden2(false);
                }

                if (isUserHost && add5MinCount == 0) {
                    setHidden5(false);
                }
            }

            console.log('2 min warn');

            return (
                <span>
                    Remaining time: {formatTime(minutes, seconds)} <TwoMinWarn />
                </span>
            );
        }

        if (startTime > 0 && minutes > 0 && seconds > 0) {
            if (isUserHost && add2MinCount == 0 && room?.participants.size > 0) {
                setHidden2(false);
            } else {
                setHidden2(true);
            }

            if (isUserHost && add5MinCount == 0 && room?.participants.size > 0) {
                setHidden5(false);
            } else {
                setHidden5(true);
            }

            return <span>Remaining time: {formatTime(minutes, seconds)}</span>;
        }

        if (minutes === 0 && seconds === 0 && completed) {
            showRemainingTime = false;
            return (
                <span>
                    Another successful coffeeBreak!
                    <Confetti
                        width={width}
                        height={height}
                        numberOfPieces={800}
                        opacity={0.5}
                        initialVelocityX={8}
                        initialVelocityY={20}
                        recycle={!isComplete()}
                    />
                    {shutDown()}
                </span>
            );
        }

        return <span>Remaining time: {formatTime(minutes, seconds)}</span>;
    };

    function renderMeetingInfo() {
        const imgUrl = process.env.PUBLIC_URL + '/coffeeBreak.png';

        return (
            <div
                data-cy-main-participant
                data-cy-participant={participant.identity}
                className={clsx(classes.container, {
                    [classes.fullWidth]: !isRemoteParticipantScreenSharing,
                })}
            >
                <div className={classes.infoContainer}>
                    <div className={classes.identity}>
                        <img width="50px" id="3" height="50px" src={imgUrl}></img>
                        <AudioLevelIndicator audioTrack={audioTrack} />
                        <Typography component={'span'} variant="body1" color="inherit">
                            {participant.identity}
                            <div>{isLocal && '(You)'}</div>

                            {showWaiting && <MeetingNotStarted />}
                            {showRemainingTime && startTime > 0 && (
                                <div style={{ fontFamily: 'inherit', marginTop: '.25em', marginBottom: '.75em' }}>
                                    <Countdown date={startTime + meetingDuration} renderer={renderer} />
                                </div>
                            )}
                        </Typography>
                    </div>
                    <div className={classes.infoContainer}>
                        {isUserHost && (
                            <ButtonGroup>
                                <Button
                                    color="primary"
                                    disabled={hidden2}
                                    variant="contained"
                                    onClick={async () => {
                                        await setStartTime(meetingDuration + 120000);
                                        setHidden2(true);
                                        add2MinCount = 1;
                                    }}
                                >
                                    Add 2 min
                                </Button>
                                <div></div>
                                <div></div>
                                <div></div>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={hidden5}
                                    onClick={async () => {
                                        await setStartTime(meetingDuration + 300000);
                                        setHidden5(true);
                                        add5MinCount = 1;
                                    }}
                                >
                                    Add 5 min
                                </Button>
                            </ButtonGroup>
                        )}
                        {isHostAnAdmin && (
                            <div style={{ marginTop: 10 + 'px' }}>
                                <Typography component={'div'} variant="body1" color="inherit">
                                    {'Disable cutoff'}
                                    <input name="disableCutoff" type="checkbox" onChange={handleChange}></input>
                                </Typography>
                            </div>
                        )}
                    </div>
                </div>

                {(!isVideoEnabled || isVideoSwitchedOff) && (
                    <div className={classes.avatarContainer}>
                        <AvatarIcon />
                    </div>
                )}
                {isParticipantReconnecting && (
                    <div className={classes.reconnectingContainer}>
                        <Typography component={'span'} variant="body1" style={{ color: 'white' }}>
                            Reconnecting...
                        </Typography>
                    </div>
                )}
                {children}
            </div>
        );
    }

    return renderMeetingInfo();
}
