import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import firebase from 'firebase/compat/app';
import UserPostMeetingPage from '../../PostMeeting/PostMeeting';
import { useWindowScroll } from 'react-use';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            background: theme.brand,
            color: 'white',
            '&:hover': {
                background: '#600101',
            },
        },
    })
);

export default function EndCallButton(props: { className?: string }) {
    const classes = useStyles();
    const { room } = useVideoContext();

    return (
        <Button
            onClick={() => {
                const analytics = firebase.analytics();
                analytics.logEvent('endcall_button_press');

                var temp = window.location.pathname.substring('meetingId');
                var pathArray = temp.split('/');
                var hostUid = '';

                room!.disconnect();
                if (room?.participants.size === 0) {
                    if (pathArray.length > 2) {
                        hostUid = pathArray[2];
                        console.log(hostUid);

                        var url =
                            'https://us-central1-coffebreak-a15f1.cloudfunctions.net/functionClearHostActiveMeeting' +
                            '?hostUid=' +
                            hostUid;

                        const encoded = encodeURI(url);
                        console.log('url: ', encoded);
                        try {
                            var startTime = 0;

                            const docRef2 = firebase
                                .firestore()
                                .collection('meetingStartInfo')
                                .doc(room?.name);

                            docRef2.get().then(doc => {
                                if (doc.exists) {
                                    startTime = Number(doc.data()['startTime']);
                                }
                            });

                            const docRef = firebase
                                .firestore()
                                .collection('meetingStartInfo')
                                .doc(room?.name)
                                .delete()
                                .then(() => {
                                    console.log('Document successfully deleted!');
                                    try {
                                        fetch(encoded, {
                                            method: 'GET',
                                            //mode: 'no-cors',
                                        }).then(async res => {
                                            if (!res.ok) {
                                                console.log('error: ', res);
                                            }

                                            if (startTime > 0) {
                                                window.location = '/postmeeting';
                                            }
                                        });
                                    } catch (error) {
                                        console.log(error);
                                    }
                                })
                                .catch(error => {
                                    console.error('Error removing document: ', error);
                                });
                        } catch (error) {
                            console.log(error);
                        }
                    }
                } else {
                    room!.disconnect();
                }
            }}
            className={clsx(classes.button, props.className)}
            data-cy-disconnect
        >
            Disconnect
        </Button>
    );
}
