import { Container, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        marginTop: '2.5em',
    },
    paper: {
        padding: '1em',
    },
    heading: {
        marginBottom: '0.4em',
    },
});

export default function HowtoTest() {
    const imgUrl = process.env.PUBLIC_URL + '/coffeeBreak.png';
    const classes = useStyles();
    const Wrapper = styled.div`
        twiliologo: {
            position: 'absolute';
            top: 10;
            left: 5;
            width: '40px';
        }
        section {
            color: #fff;
        }
        min-height: calc(100vh - 50px);
        background-color: #94ceb9;
        padding: 20px;
        overflow-y: auto;
        height: 20px;

        span {
            color: #fff;
            font-size: 20px;
        }

        twiliologo: {
            position: 'absolute';
            top: 10;
            left: 5;
            width: '40px';
        }
        section {
            color: #fff;
        }
    `;
    return (
        <Wrapper>
            <div>
                <a href="/">
                    <img width="50" id="1" className="twilioLogo" src={imgUrl}></img>
                </a>
            </div>
            <span>How to test</span>
            <nav>
                <ul>
                    <h5>Test #1 - 5 days from initial install</h5>
                    <p>
                        <li>Sign-up & Log-in</li>
                        <li>Create & populate your User Profile</li>
                        <li>Preview your card</li>
                        <li>Review your daily matches & connect with at least 3 people total</li>
                        <li>Schedule at least 2 coffeeBreaks</li>
                    </p>
                </ul>

                <div>
                    <div>
                        <p />
                        <ul>
                            <h5>How to Report bugs from within the App</h5>
                            <li>Take a screenshot</li>
                            <ul>
                                <li>Press the Side Button + Volume Up button at the same time (iPhones with FaceID)</li>
                                <li>Press the Side button + Home button at the same time (iPhones with TouchID)</li>
                            </ul>
                            <li>Share the screenshot using “Share Beta Feedback” button</li>
                            <li>Add comments to describe bug/ additional details</li>
                            <li>See below for example</li>
                        </ul>
                    </div>
                </div>
                <img src="uploadscreenshot.gif"></img>
            </nav>
        </Wrapper>
    );
}
