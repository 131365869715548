import { Callback } from '../../../types';
import { isMobile } from '../../../utils';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactCanvasConfetti, { IProps } from 'react-canvas-confetti';
import firebase from 'firebase/compat/app';
import 'firebase/analytics';
import 'firebase/firestore';

// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
    const [room, setRoom] = useState<Room | null>(null);
    const [isConnecting, setIsConnecting] = useState(false);
    const optionsRef = useRef(options);

    useEffect(() => {
        // This allows the connect function to always access the most recent version of the options object. This allows us to
        // reliably use the connect function at any time.
        optionsRef.current = options;
    }, [options]);

    const handleEnd = room => {
        const analytics = firebase.analytics();
        analytics.logEvent('endcall_meeting');

        var temp = window.location.pathname.substring('meetingId');
        var pathArray = temp.split('/');
        var hostUid = '';

        room.disconnect();

        if (room?.participants.size === 0) {
            if (pathArray.length > 2) {
                hostUid = pathArray[2];
                console.log(hostUid);

                var url =
                    'https://us-central1-coffebreak-a15f1.cloudfunctions.net/functionClearHostActiveMeeting' +
                    '?hostUid=' +
                    hostUid;

                const encoded = encodeURI(url);
                console.log('url: ', encoded);

                try {
                    const docRef = firebase
                        .firestore()
                        .collection('meetingStartInfo')
                        .doc(room?.name)
                        .delete()
                        .then(() => {
                            console.log('Document successfully deleted!');
                        })
                        .catch(error => {
                            console.error('Error removing document: ', error);
                            return;
                        });
                } catch (error) {
                    console.log(error);
                    return;
                }

                try {
                    fetch(encoded, {
                        method: 'GET',
                        //mode: 'no-cors',
                    }).then(async res => {
                        if (!res.ok) {
                            console.log('error: ', res);
                        }
                        return;
                    });
                } catch (error) {
                    console.log(error);
                    return;
                }
            }
        }
    };

    const connect = useCallback(
        token => {
            setIsConnecting(true);
            return Video.connect(token, { ...optionsRef.current, tracks: localTracks }).then(
                newRoom => {
                    console.log('\n\n\n\n\nnewRoom: ', newRoom);
                    setRoom(newRoom);
                    VideoRoomMonitor.registerVideoRoom(newRoom);
                    const disconnect = () => newRoom.disconnect();
                    const stats = () => newRoom.getStats();
                    console.log('stats: ', stats);

                    // This app can add up to 13 'participantDisconnected' listeners to the room object, which can trigger
                    // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
                    newRoom.setMaxListeners(2);

                    newRoom.once('disconnected', () => {
                        // Reset the room only after all other `disconnected` listeners have been called.
                        setTimeout(() => setRoom(null));
                        window.removeEventListener('beforeunload', null);

                        if (isMobile) {
                            window.removeEventListener('pagehide', null);
                        }
                    });

                    // @ts-ignore
                    window.twilioRoom = newRoom;
                    newRoom.localParticipant.videoTracks.forEach(publication =>
                        // All video tracks are published with 'low' priority because the video track
                        // that is displayed in the 'MainParticipant' component will have it's priority
                        // set to 'high' via track.setPriority()
                        publication.setPriority('low')
                    );

                    setIsConnecting(false);

                    // Add a listener to disconnect from the room when a user closes their browser
                    window.addEventListener('beforeunload', function(event) {
                        setIsConnecting(false);
                        event.returnValue = 'Write something clever here..';
                        handleEnd(newRoom);
                    });

                    if (isMobile) {
                        // Add a listener to disconnect from the room when a mobile user closes their browser
                        window.addEventListener('pagehide', function(event) {
                            setIsConnecting(false);
                            handleEnd(newRoom);
                        });
                    }
                },
                error => {
                    onError(error);
                    setIsConnecting(false);
                }
            );
        },
        [localTracks, onError]
    );

    return { room, isConnecting, connect };
}
