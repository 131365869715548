import React, { useState, useEffect, ChangeEvent } from 'react';
import { useAppState } from '../../state';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    gutterBottom: {
        marginBottom: '1em',
    },
    joinButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            width: '100%',
            '& button': {
                margin: '0.5em 0',
            },
        },
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '1.5em 0 3.5em',
        '& div:not(:last-child)': {
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '1.5em 0 2em',
        },
    },
    textFieldContainer: {
        width: '100%',
    },
    continueButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

const queryString = require('query-string');
const parsed = queryString.parse(window.location.search);

export default function InvitePage({}) {
    const classes = useStyles();
    const { user } = useAppState();

    var { URLIdentity } = useParams();
    var { URLMeetingId } = useParams();
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [roomName, setRoomName] = useState<string>('');

    useEffect(() => {
        var temp = window.sessionStorage.getItem('URLIdentity');

        if (URLIdentity === undefined) {
            if (user?.displayName !== undefined) {
                URLIdentity = user?.displayName;
            } else {
                if (temp !== 'null') {
                    URLIdentity = window.sessionStorage.getItem('URLIdentity');
                } else {
                    URLIdentity = '';
                }
            }
        } else {
            window.sessionStorage.setItem('URLIdentity', URLIdentity);
        }

        if (!URLMeetingId) {
            URLMeetingId = window.sessionStorage.getItem('URLMeetingId');
        } else {
            window.sessionStorage.setItem('URLMeetingId', URLMeetingId);
        }

        if (URLIdentity) {
            var meetingDisplayName = '';
            var names: string[];

            if (user?.displayName) {
                names = user.displayName?.split(' ');

                if (names != null && names?.length > 1) {
                    meetingDisplayName = names[0] + ' ' + names[1].substring(0, 1) + '.';
                } else {
                    meetingDisplayName = user?.displayName;
                }
            } else {
                names = URLIdentity?.split(' ');

                if (names != null && names?.length > 1) {
                    meetingDisplayName = names[0] + ' ' + names[1].substring(0, 1) + '.';
                } else {
                    meetingDisplayName = URLIdentity;
                }
            }

            if (user?.email) {
                setUserEmail(user?.email);
            }

            if (meetingDisplayName.toLocaleLowerCase() == 'guest') {
                setFirstName('');
                setLastName('');
            } else {
                setFirstName(names[0]);
                setLastName(names[1]);
            }

            if (URLMeetingId) {
                setRoomName(URLMeetingId);
            }
        }
    }, [user, URLMeetingId, URLIdentity]);

    console.log('userName: ', firstName);
    console.log('userEmail: ', userEmail);
    console.log('roomName: ', roomName);

    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUserEmail(event.target.value);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        respondToInvite('Accepted');
    };

    function ValidateEmail(mail: string) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return true;
        }
        return false;
    }

    function respondToInvite(status: string) {
        if (parsed.currentUid === '') {
            return;
        }

        let userName = firstName + ' ' + lastName;

        window.sessionStorage.setItem('currentUid', parsed.currentUid);

        var url =
            'https://us-central1-coffebreak-a15f1.cloudfunctions.net/functionUpdateMeetingInfo' +
            '?meetingId=' +
            roomName +
            '&guestDisplayName=' +
            firstName +
            ' ' +
            lastName[0] +
            '.' +
            '&userEmail=' +
            userEmail +
            '&currentUid=' +
            parsed.currentUid +
            '&status=' +
            status +
            '&meetingTitle=' +
            'coffeeBreak with ' +
            userName;

        const encoded = encodeURI(url);
        console.log('url: ', encoded);

        fetch(encoded, {
            method: 'GET',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (status === 'Accepted') {
            alert('Invite Sent');
        }

        if (status === 'Cancelled') {
            alert('Meeting Cancelled');
        }

        window.location = 'https://letscoffeebreak.com';
    }

    //const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;
    const hasUsername = false;
    let history = useHistory();

    return (
        <IntroContainer>
            <Typography variant="h6" className={classes.gutterBottom}>
                You have been invited to a coffeeBreak
                <br />
                Meeting time: {parsed.meetingTime}
            </Typography>
            <Typography variant="body1">
                {hasUsername
                    ? 'Enter your email so we can send you the invite'
                    : 'Enter your name and email so we can send you the invite'}
            </Typography>
            <form onSubmit={handleSubmit}>
                <div className={classes.inputContainer}>
                    {!hasUsername && (
                        <div className={classes.textFieldContainer}>
                            <InputLabel shrink htmlFor="input-user-first-name">
                                First Name
                            </InputLabel>
                            <TextField
                                id="input-user-first-name"
                                variant="outlined"
                                size="small"
                                value={firstName}
                                onChange={handleFirstNameChange}
                            />
                        </div>
                    )}
                    <div className={classes.textFieldContainer}>
                        <InputLabel shrink htmlFor="input-user-last-name">
                            Last Name
                        </InputLabel>
                        <TextField
                            id="input-user-last-name"
                            variant="outlined"
                            size="small"
                            value={lastName}
                            onChange={handleLastNameChange}
                        />
                    </div>
                    <div className={classes.textFieldContainer}>
                        <InputLabel shrink htmlFor="input-user-email">
                            Email
                        </InputLabel>
                        <TextField
                            autoCapitalize="false"
                            id="input-user-email"
                            variant="outlined"
                            value={userEmail}
                            fullWidth
                            size="small"
                            onChange={handleEmailChange}
                        />
                    </div>
                </div>

                <div className={classes.joinButtons}>
                    <Button variant="outlined" color="primary" onClick={() => respondToInvite('Cancelled')}>
                        Decline
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={!firstName || !ValidateEmail(userEmail)}
                        className={classes.continueButton}
                    >
                        Accept
                    </Button>
                </div>
            </form>
        </IntroContainer>
    );
}
