import React, { createContext, useContext, useReducer, useState } from 'react';
import { RecordingRules, RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';

// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import LoginPage from '../components/LoginPage/LoginPage';
import { useLocation, useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

export interface StateContextType {
    error: TwilioError | Error | null;
    setError(error: TwilioError | Error | null): void;
    getToken(name: string, room: string, passcode?: string): Promise<{ room_type: RoomType; token: string }>;
    user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
    signIn?(passcode?: string): Promise<void>;
    signOut?(): Promise<void>;
    isAuthReady?: boolean;
    isFetching: boolean;
    firebaseUser: firebase.User;
    activeSinkId: string;
    setActiveSinkId(sinkId: string): void;
    settings: Settings;
    dispatchSetting: React.Dispatch<SettingsAction>;
    roomType?: RoomType;
    updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
    const [error, setError] = useState<TwilioError | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [userLoggedOut, setUserLoggedOut] = useState(false);
    const [activeSinkId, setActiveSinkId] = useActiveSinkId();
    const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
    const [roomType, setRoomType] = useState<RoomType>();
    const [firebaseUser, setfirebaseUser] = useState<firebase.User>();
    const history = useHistory();

    let contextValue = {
        error,
        setError,
        isFetching,
        activeSinkId,
        setActiveSinkId,
        settings,
        dispatchSetting,
        roomType,
        firebaseUser,
    } as StateContextType;

    React.useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            // detaching the listener
            if (user) {
                if (firebaseUser == null) {
                    setfirebaseUser(user);
                }
            } else {
                setUserLoggedOut(true);
            }
        });
        return () => unsubscribe(); // unsubscribing from the listener when the component is unmounting.
    }, []);

    if (process.env.REACT_APP_SET_AUTH === 'firebase') {
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_APP_ID,
            measurementId: process.env.REACT_APP_MEASUREMENT_ID,
        };

        if (firebase.apps.length === 0) {
            firebase.initializeApp(firebaseConfig);
            firebase.analytics.isSupported().then(isSupported => {
                if (isSupported) {
                    firebase.analytics();
                }
            });
        }

        contextValue = {
            ...contextValue,
            ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
        };
    } else {
        contextValue = {
            ...contextValue,
            getToken: async (user_identity, room_name) => {
                const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
                return fetch(endpoint, {
                    mode: 'no-cors',
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    },
                    body: JSON.stringify({
                        user_identity,
                        room_name,
                        create_conversation: process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true',
                    }),
                }).then(res => {
                    console.log('TOKEN: ', res.json);
                    res.json();
                });
            },
        };
    }

    const getToken: StateContextType['getToken'] = (name, room, userEmail) => {
        setIsFetching(true);
        return contextValue
            .getToken(name, room, userEmail)
            .then(res => {
                /*
                const parsedToken = JSON.parse(atob(res.split('.')[1]));
                console.log(parsedToken.grants.video.room);

                if (room != parsedToken.grants.video.room) {
                    var temp = window.location.href;
                    temp = temp.replace(room, parsedToken.grants.video.room);
                    window.location = temp;
                }
*/
                //setRoomType(res.room_type);
                setRoomType('group-small');
                setIsFetching(false);
                return res;
            })
            .catch(err => {
                setError(err);
                setIsFetching(false);
                return Promise.reject(err);
            });
    };

    const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
        setIsFetching(true);
        return contextValue
            .updateRecordingRules(room_sid, rules)
            .then(res => {
                setIsFetching(false);
                return res;
            })
            .catch(err => {
                setError(err);
                setIsFetching(false);
                return Promise.reject(err);
            });
    };

    return (
        <StateContext.Provider value={{ ...contextValue, getToken, updateRecordingRules }}>
            {props.children}
        </StateContext.Provider>
    );
}

export function useAppState() {
    const context = useContext(StateContext);
    if (!context) {
        throw new Error('useAppState must be used within the AppStateProvider');
    }
    return context;
}
